@font-face {
    font-family: 'Aileron';
    src: url('../../public/fonts/Aileron-Regular.woff2') format('woff2'), 
         url('../../public/fonts/Aileron-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

.header-container {
    position: relative;
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #E4E2DD;
  }

.title {
  font-size: 68px;
  font-weight: bold;
  margin: 0;
  color: #DB4A2B;
  position: relative;
  z-index: 1;
  text-shadow: 1px 1px 0 #DB4A2B, -1px -1px 0 #DB4A2B, 1px -1px 0 #DB4A2B, -1px 1px 0 #DB4A2B;
}


.subtitle-container {
    position: relative;
    margin-top: 0px;
    margin-left: 120px;
    z-index: 1;
    text-shadow: 0.6px 0.6px 0 #DB4A2B, -0.6px -0.6px 0 #DB4A2B, 0.6px -0.6px 0 #DB4A2B, -0.6px 0.6px 0 #DB4A2B;
    font-family: 'Aileron', sans-serif; 
    transform: scaleX(0.7);
    
  }
.subtitle {
    font-size: 18px;
    color: #DB4A2B;
}


.background-image {
    position: absolute;
    top: 76%;
    left: 59%;
    transform: translate(-50%, -50%);
    width: 10%;
    height: auto;
    opacity: 1;
    z-index: 0;
  }

header.masthead h1 {
    font-size: 2rem;
    color: #DB4A2B;
    font-family: 'Bernoru', sans-serif;
}
@media (min-width: 768px) {
    header.masthead {
        padding-top: 12rem;
        padding-bottom: 12rem;
    }
    header.masthead h1 {
        font-size: 3rem;
    }
}


